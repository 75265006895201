// src/Signup.js

import React, { useState, useContext, useEffect } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { createUser } from './SignupProcessor';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { Helmet } from 'react-helmet';

const Signup = () => {
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    location: '',
    countryCode: '+44',
    marketingConsent: false,
    terms: false
  });

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = input => e => {
    console.log(`Changing ${input}:`, e.target.value);
    setFormData({ ...formData, [input]: e.target.value });
  };

  const goBack = () => {
    navigate('/');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!Object.values(formData).every(value => typeof value === 'string' ? value.trim() !== '' : true)) {
        throw new Error('Please fill out all fields.');
      }
      const fullPhoneNumber = `${formData.countryCode}${formData.phone}`;
      await createUser(formData.email, formData.password, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: fullPhoneNumber,
        email: formData.email,
        location: formData.location,
        marketingConsent: formData.marketingConsent,
        terms: formData.terms
      });
      navigate('/dashboard');
    } catch (error) {
      console.error('Signup error:', error);
      alert(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900">Creating your account...</h1>
          <p className="text-gray-600 mt-2">Please wait while we set everything up for you.</p>
        </div>
      </div>
    );
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return <StepOne nextStep={nextStep} handleChange={handleChange} values={formData} />;
      case 2:
        return <StepTwo prevStep={prevStep} handleChange={handleChange} handleSubmit={handleSubmit} values={formData} />;
      default:
        return <div>Step {step}</div>;
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>Sign Up - bloganywhere</title>
        <meta name="description" content="Create your bloganywhere account and start managing your blog content more efficiently." />
      </Helmet>
      
      <div className="flex min-h-screen">
        {/* Left Column - Branding */}
        <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-blue-600 to-blue-800 p-12 flex-col justify-between">
          <div>
            <h1 className="text-4xl font-bold text-white mb-6">bloganywhere</h1>
            <p className="text-xl text-blue-100 mb-8">Join thousands of content creators who trust bloganywhere to manage their blog content.</p>
            
            <div className="space-y-6">
              <div className="flex items-center space-x-3">
                <svg className="w-6 h-6 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="text-blue-100">30-day free trial, no credit card required</p>
              </div>
              <div className="flex items-center space-x-3">
                <svg className="w-6 h-6 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="text-blue-100">Full access to all premium features</p>
              </div>
              <div className="flex items-center space-x-3">
                <svg className="w-6 h-6 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="text-blue-100">Cancel anytime, hassle-free</p>
              </div>
            </div>
          </div>
          
          <div className="text-blue-100 text-sm">
            <p>Trusted by leading content creators and businesses worldwide</p>
          </div>
        </div>

        {/* Right Column - Signup Form */}
        <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
          <div className="w-full max-w-md">
            <button onClick={goBack} className="text-gray-600 mb-8 flex items-center hover:text-gray-900">
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back to home
            </button>

            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-900">Create your account</h2>
              <p className="text-gray-600 mt-2">Step {step} of 2</p>
            </div>

            <div className="bg-white rounded-lg">
              {renderStep()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
