import React from 'react';
import { motion } from 'framer-motion';
import { FiZap, FiTrendingUp, FiClock, FiSearch } from 'react-icons/fi';

const Home = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Navbar */}
      <motion.nav
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="fixed top-0 left-0 right-0 w-full z-50 backdrop-blur-lg bg-white/70 border-b border-gray-100 shadow-sm"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <a href="/" className="flex items-center space-x-2">
              <span className="text-2xl font-bold bg-gradient-to-r from-violet-600 to-indigo-600 text-transparent bg-clip-text">
                bloganywhe.re
              </span>
            </a>
            <div className="flex items-center space-x-4">
              <a href="/welcome" className="text-gray-600 hover:text-gray-900 transition">
                Login
              </a>
              <a
                href="/signup"
                className="px-4 py-2 rounded-full bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:from-violet-700 hover:to-indigo-700 transition"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </motion.nav>

      {/* Hero Section */}
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="relative pt-32 pb-20 sm:pt-40 sm:pb-24 bg-gradient-to-b from-violet-50 to-white overflow-hidden"
      >
        <div className="absolute inset-0 overflow-hidden">
          {/* Optional patterned background */}
          <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.3 }}
              className="text-4xl sm:text-6xl font-bold tracking-tight text-gray-900 pb-4"
            >
              Transform Your Website with{' '}
              <span className="bg-gradient-to-r from-violet-600 to-indigo-600 text-transparent bg-clip-text">
                AI-Powered Blogs
              </span>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.5 }}
              className="mt-6 text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto"
            >
              Let AI create engaging, SEO-optimized content while you focus on growing your business.
              One click to generate, optimize, and publish.
            </motion.p>
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.7 }}
              className="mt-10 flex flex-col sm:flex-row justify-center gap-4"
            >
              <a
                href="/signup"
                className="px-8 py-4 rounded-full bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:from-violet-700 hover:to-indigo-700 font-semibold text-lg transition-all transform hover:scale-105"
              >
                Start Creating Now
              </a>
              <a
                href="#demo"
                className="px-8 py-4 rounded-full border-2 border-violet-200 text-gray-700 hover:border-violet-300 font-semibold text-lg transition-all"
              >
                Watch Demo
              </a>
            </motion.div>

            {/* AI Generation Stats */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.9 }}
              className="mt-16 grid grid-cols-2 lg:grid-cols-4 gap-8 max-w-4xl mx-auto"
            >
              <div className="flex flex-col items-center p-6 bg-white rounded-2xl shadow-sm border border-gray-100">
                <FiZap className="w-8 h-8 text-violet-600 mb-2" />
                <span className="text-3xl font-bold text-gray-900">2s</span>
                <span className="text-gray-600">Average Generation Time</span>
              </div>
              <div className="flex flex-col items-center p-6 bg-white rounded-2xl shadow-sm border border-gray-100">
                <FiTrendingUp className="w-8 h-8 text-violet-600 mb-2" />
                <span className="text-3xl font-bold text-gray-900">89%</span>
                <span className="text-gray-600">SEO Improvement</span>
              </div>
              <div className="flex flex-col items-center p-6 bg-white rounded-2xl shadow-sm border border-gray-100">
                <FiClock className="w-8 h-8 text-violet-600 mb-2" />
                <span className="text-3xl font-bold text-gray-900">5hrs</span>
                <span className="text-gray-600">Time Saved per Post</span>
              </div>
              <div className="flex flex-col items-center p-6 bg-white rounded-2xl shadow-sm border border-gray-100">
                <FiSearch className="w-8 h-8 text-violet-600 mb-2" />
                <span className="text-3xl font-bold text-gray-900">3x</span>
                <span className="text-gray-600">Search Traffic Increase</span>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>

      {/* Features Grid */}
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1.2 }}
        className="relative py-24 bg-gradient-to-b from-white to-violet-50"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-20">
            <span className="text-violet-600 font-semibold">Features</span>
            <h2 className="mt-2 text-4xl font-bold text-gray-900">
              AI-Powered Blog Generation, <span className="text-violet-600">Simplified</span>
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Everything you need to create engaging content at scale
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              whileHover={{ scale: 1.03 }}
              className="relative p-8 bg-white rounded-2xl shadow-sm transition-all duration-300 border border-gray-100"
            >
              <div className="absolute -top-4 left-8 inline-flex items-center justify-center p-2 bg-violet-100 rounded-lg">
                <svg
                  className="w-6 h-6 text-violet-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <h3 className="mt-4 text-xl font-semibold text-gray-900">Instant Generation</h3>
              <p className="mt-3 text-gray-600">
                Generate SEO-optimized blog posts in seconds with our advanced AI technology.
              </p>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.03 }}
              className="relative p-8 bg-white rounded-2xl shadow-sm transition-all duration-300 border border-gray-100"
            >
              <div className="absolute -top-4 left-8 inline-flex items-center justify-center p-2 bg-violet-100 rounded-lg">
                <svg
                  className="w-6 h-6 text-violet-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                  />
                </svg>
              </div>
              <h3 className="mt-4 text-xl font-semibold text-gray-900">SEO Optimization</h3>
              <p className="mt-3 text-gray-600">
                Built-in SEO tools ensure your content ranks higher in search results.
              </p>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.03 }}
              className="relative p-8 bg-white rounded-2xl shadow-sm transition-all duration-300 border border-gray-100"
            >
              <div className="absolute -top-4 left-8 inline-flex items-center justify-center p-2 bg-violet-100 rounded-lg">
                <svg
                  className="w-6 h-6 text-violet-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </div>
              <h3 className="mt-4 text-xl font-semibold text-gray-900">Auto Publishing</h3>
              <p className="mt-3 text-gray-600">
                Schedule and automatically publish content to your website.
              </p>
            </motion.div>
          </div>
        </div>
      </motion.section>

      {/* Pricing Section */}
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1.5 }}
        className="relative py-24 overflow-hidden"
      >
        <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-20">
            <span className="text-violet-600 font-semibold">Pricing</span>
            <h2 className="mt-2 text-4xl font-bold text-gray-900">
              Start Creating <span className="text-violet-600">Today</span>
            </h2>
            <p className="mt-4 text-xl text-gray-600">Simple, transparent pricing that grows with you</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="relative p-8 bg-white rounded-2xl shadow-xl border border-violet-100 transition-all duration-300"
            >
              <div className="absolute -top-4 left-1/2 -translate-x-1/2 inline-block px-4 py-1 bg-violet-600 rounded-full text-white text-sm font-semibold">
                Get Started Free
              </div>
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold text-gray-900">Starter Blogger</h3>
                <div className="mt-4 flex items-baseline justify-center gap-2">
                  <span className="text-5xl font-bold text-gray-900">$0</span>
                  <span className="text-gray-600">/month</span>
                </div>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center gap-3 text-gray-600">
                  <svg
                    className="w-5 h-5 text-violet-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Up to 10 blog posts per month
                </li>
              </ul>
              <a
                href="/signup"
                className="block w-full py-3 px-6 text-center text-white bg-violet-600 hover:bg-violet-700 rounded-lg font-semibold transition-colors"
              >
                Get Started
              </a>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              className="relative p-8 bg-white rounded-2xl shadow-xl border border-violet-100 transition-all duration-300"
            >
              <div className="absolute -top-4 left-1/2 -translate-x-1/2 inline-block px-4 py-1 bg-violet-600 rounded-full text-white text-sm font-semibold">
                Most Popular
              </div>
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold text-gray-900">Pro Blogger</h3>
                <div className="mt-4 flex items-baseline justify-center gap-2">
                  <span className="text-5xl font-bold text-gray-900">$29</span>
                  <span className="text-gray-600">/month</span>
                </div>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center gap-3 text-gray-600">
                  <svg
                    className="w-5 h-5 text-violet-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Unlimited blog posts
                </li>
                <li className="flex items-center gap-3 text-gray-600">
                  <svg
                    className="w-5 h-5 text-violet-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Advanced SEO optimization
                </li>
                <li className="flex items-center gap-3 text-gray-600">
                  <svg
                    className="w-5 h-5 text-violet-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Auto-scheduling & publishing
                </li>
                <li className="flex items-center gap-3 text-gray-600">
                  <svg
                    className="w-5 h-5 text-violet-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Priority support
                </li>
              </ul>
              <a
                href="/signup"
                className="block w-full py-3 px-6 text-center text-white bg-gradient-to-r from-violet-600 to-indigo-600 hover:from-violet-700 hover:to-indigo-700 rounded-lg font-semibold transition-colors"
              >
                Get Started
              </a>
            </motion.div>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default Home;
