import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { ArrowLeftIcon, CalendarIcon, GlobeAltIcon } from '@heroicons/react/outline';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const UserBlogPost = () => {
  const { blogId, postId } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [themeColor, setThemeColor] = useState('#000000');
  const [textColor, setTextColor] = useState('#FFFFFF');
  const [originalUrl, setOriginalUrl] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!blogId || !postId) {
          throw new Error("Blog ID or Post ID is undefined");
        }

        const [userId, actualBlogId] = blogId.split('-');
        const postDocPath = `users/${userId}/blogs/${actualBlogId}/posts/${postId}`;
        const blogDocPath = `users/${userId}/blogs/${actualBlogId}`;
        console.log(`Fetching post from document path: ${postDocPath}`);

        const postDocRef = doc(db, postDocPath);
        const postDoc = await getDoc(postDocRef);

        const blogDocRef = doc(db, blogDocPath);
        const blogDoc = await getDoc(blogDocRef);

        if (blogDoc.exists()) {
          const blogData = blogDoc.data();
          const color = blogData.colors ? blogData.colors[0] : '#000000';
          setThemeColor(color);
          setTextColor(getTextColor(color));
          setOriginalUrl(blogData.websiteUrl || '');
        }

        if (!postDoc.exists()) {
          setError("No such post found.");
          return;
        }

        setPost(postDoc.data());
      } catch (err) {
        console.error('Error fetching post:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (blogId === 'test-long-random-string') {
      const dummyPost = {
        title: 'Lorem Ipsum Dolor Sit Amet',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
      };
      setPost(dummyPost);
      setLoading(false);
    } else {
      fetchPost();
    }
  }, [blogId, postId]);

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  const getTextColor = (bgColor) => {
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 186 ? '#000000' : '#FFFFFF';
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-violet-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="max-w-md p-6 bg-white rounded-2xl shadow-sm border border-red-100">
          <p className="text-red-600">{error}</p>
        </div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="max-w-md p-6 bg-white rounded-2xl shadow-sm border border-gray-100">
          <p className="text-gray-600">Loading post...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div 
        className="relative overflow-hidden bg-gradient-to-r from-violet-600 to-indigo-600"
        style={{ backgroundColor: themeColor }}
      >
        <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>
        <div className="relative max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="flex items-center justify-between mb-8">
            <button
              onClick={() => navigate(`/${blogId}`)}
              className="inline-flex items-center px-4 py-2 rounded-full bg-white/20 hover:bg-white/30 text-white font-medium transition-colors"
            >
              <ArrowLeftIcon className="h-4 w-4 mr-2" />
              Back to Blog
            </button>
            
            {originalUrl && (
              <a
                href={originalUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 rounded-full bg-white/20 hover:bg-white/30 text-white font-medium transition-colors"
              >
                <GlobeAltIcon className="h-4 w-4 mr-2" />
                View Website
              </a>
            )}
          </div>
          
          <h1 className="text-3xl md:text-4xl font-bold text-white text-center">
            {stripLeadingHash(post.title)}
          </h1>
          
          {post.createdAt && (
            <div className="mt-4 flex items-center justify-center text-white">
              <CalendarIcon className="h-5 w-5 mr-2" />
              {post.createdAt.toDate().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </div>
          )}
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <article className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="p-8 sm:p-12">
            <div className="prose prose-lg max-w-none 
              prose-headings:font-bold prose-headings:text-gray-900 
              prose-h1:text-3xl prose-h1:mt-8 prose-h1:mb-4
              prose-h2:text-2xl prose-h2:mt-6 prose-h2:mb-4
              prose-h3:text-xl prose-h3:mt-4 prose-h3:mb-2
              prose-p:text-black prose-p:leading-relaxed prose-p:mb-4
              prose-a:text-violet-600 prose-a:font-medium prose-a:no-underline hover:prose-a:text-violet-700
              prose-strong:text-black prose-strong:font-semibold
              prose-ul:my-4 prose-ul:list-disc prose-ul:pl-6
              prose-ol:my-4 prose-ol:list-decimal prose-ol:pl-6
              prose-li:text-black prose-li:mb-2
              prose-blockquote:border-l-4 prose-blockquote:border-violet-200 prose-blockquote:pl-4 prose-blockquote:italic prose-blockquote:text-black
              prose-code:text-violet-600 prose-code:bg-violet-50 prose-code:px-1 prose-code:rounded
              prose-pre:bg-gray-900 prose-pre:text-gray-100 prose-pre:p-4 prose-pre:rounded-lg
              prose-img:rounded-lg prose-img:shadow-sm
              prose-hr:my-8 prose-hr:border-gray-200"
            >
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {post.content || ''}
              </ReactMarkdown>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default UserBlogPost;
