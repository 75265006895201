import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Home from './Home';
import Welcome from './signup/Welcome';
import Signup from './signup/Signup';
import Login from './signup/Login';
import PasswordReset from './signup/PasswordReset';
import Dashboard from './Dashboard';
import Posts from './Posts';
import AddBlog from './AddBlog';
import Profile from './Profile';
import UserBlogHome from './UserBlogs/UserBlogHome';
import UserBlogPost from './UserBlogs/UserBlogPost';
import DnsDetails from './DnsDetails';
import CreatePosts from './CreatePosts';
import ErrorPage from './ErrorPage'; // New error page for unknown blogs
import './styles/style.css';

const AppNavigator = () => {
  const [blogIdentifier, setBlogIdentifier] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const host = window.location.hostname;
    const path = window.location.pathname.substring(1); // Remove leading slash
    
    if (host.includes("blog.")) {
      const blogName = host.split(".")[0].replace("blog", "").trim();
      if (blogName) {
        setBlogIdentifier(blogName);
      }
    } else if (path.match(/^[A-Za-z0-9-_]+$/)) {
      // Check if the path matches the blog ID pattern
      setBlogIdentifier(path);
    }
  }, [location]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        
        {/* Protected Routes */}
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/posts" element={<ProtectedRoute><Posts /></ProtectedRoute>} />
        <Route path="/add-blog" element={<ProtectedRoute><AddBlog /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/dns-details/:blogId" element={<ProtectedRoute><DnsDetails /></ProtectedRoute>} />
        <Route path="/create-posts" element={<ProtectedRoute><CreatePosts /></ProtectedRoute>} />
        
        {/* Public Blog Routes */}
        {blogIdentifier ? (
          <>
            <Route path="/post/:postId" element={<UserBlogPost />} />
            <Route path="/" element={<UserBlogHome blogIdentifier={blogIdentifier} />} />
          </>
        ) : (
          <Route path="*" element={<ErrorPage />} />
        )}
        
        {/* Blog Routes */}
        <Route path="/post/:postId" element={<UserBlogPost />} />
        <Route path="/:blogId" element={<UserBlogHome />} />
        
        {/* Fallback route */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default AppNavigator;
