import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppNavigator from './AppNavigator';
import { AuthProvider } from './AuthContext';
import './tailwind.css';

function App() {
  const [blogId, setBlogId] = useState(null);

  useEffect(() => {
    const host = window.location.hostname;
    if (host.includes("blog.")) {
      // Extract the blog identifier from the subdomain
      const blogName = host.split(".")[0].replace("blog", "").trim();
      if (blogName) {
        setBlogId(blogName);
      }
    }
  }, []);

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <AppNavigator blogId={blogId} />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
