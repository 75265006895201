// src/AddBlog.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { db } from './firebaseConfig';
import { collection, addDoc, getDocs, setDoc, serverTimestamp } from 'firebase/firestore';
import Navbar from './Navbar';
import { useAuth } from './AuthContext';
import { SparklesIcon, GlobeAltIcon } from '@heroicons/react/outline';

const AddBlog = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    let timer;
    if (loading) {
      const messages = [
        'Analyzing your website...',
        'Extracting key themes...',
        'Training AI model...',
        'Generating content ideas...',
        'Optimizing for SEO...',
      ];
      let index = 0;

      timer = setInterval(() => {
        setLoadingMessage(messages[index]);
        index = (index + 1) % messages.length;
      }, 2000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [loading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    console.log('Submitting blog URL:', url);

    try {
      // Step 1: Fetch and store the blog information
      console.log('Fetching website information...');
      const response = await axios.post('https://post-generator-4nbyelqcha-ew.a.run.app/fetch-website', {
        url: url,
        uid: currentUser.uid,
      });

      console.log('Website information fetched:', response.data);

      const themeData = response.data;

      // Flatten the arrays if necessary
      const flattenArray = (arr) => [].concat(...arr);

      // Check if the blog already exists
      console.log('Checking if the blog already exists...');
      const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
      const blogsSnapshot = await getDocs(blogsRef);
      let blogDocRef = null;

      blogsSnapshot.forEach((doc) => {
        if (doc.data().websiteUrl === url) {
          blogDocRef = doc.ref;
        }
      });

      if (!blogDocRef) {
        console.log('Blog does not exist, creating new blog...');
        blogDocRef = await addDoc(collection(db, 'users', currentUser.uid, 'blogs'), {
          websiteUrl: url,
          websiteName: new URL(url).hostname,
          description: themeData.description,
          headings: flattenArray(themeData.headings),
          keywords: flattenArray(themeData.keywords),
          keywords_meta: themeData.keywords_meta,
          topics: flattenArray(themeData.topics),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        console.log('New blog created:', blogDocRef.id);
      } else {
        // Update the existing blog with new theme data
        console.log('Blog already exists, updating blog...');
        await setDoc(blogDocRef, {
          description: themeData.description,
          headings: flattenArray(themeData.headings),
          keywords: flattenArray(themeData.keywords),
          keywords_meta: themeData.keywords_meta,
          topics: flattenArray(themeData.topics),
          updatedAt: serverTimestamp(),
        }, { merge: true });
        console.log('Blog updated:', blogDocRef.id);
      }

      // Step 2: Generate posts for the new blog
      const existingTitles = [];

      console.log('Generating posts...');
      const postsResponse = await axios.post('https://post-generator-4nbyelqcha-ew.a.run.app/generate-posts', {
        url: url,
        uid: currentUser.uid,
        num_posts: 1,
        existing_titles: existingTitles,
      });

      const posts = postsResponse.data;
      console.log('Posts generated:', posts);

      for (const postContent of posts) {
        await addDoc(collection(db, 'users', currentUser.uid, 'blogs', blogDocRef.id, 'posts'), {
          title: postContent.split('\n')[0].replace('Title: ', ''),
          content: postContent.split('\n').slice(1).join('\n'),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        console.log('Post added to Firestore:', postContent.split('\n')[0].replace('Title: ', ''));
      }

      setLoading(false);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error generating posts:', error);
      setLoading(false);
      setError('Failed to generate posts. Please try again.');
    }
  };

  return (
    <>
    <Navbar />
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-violet-100 mb-4">
            <GlobeAltIcon className="h-8 w-8 text-violet-600" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900">Add Your Website</h1>
          <p className="mt-2 text-lg text-gray-600">
            Let AI analyze your website and create engaging, on-brand content
          </p>
        </div>

        {loading ? (
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8">
            <div className="flex flex-col items-center justify-center space-y-6">
              <div className="relative w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                <div className="absolute top-0 left-0 h-full w-1/2 bg-gradient-to-r from-violet-600 to-indigo-600 rounded-full animate-loading"></div>
              </div>
              <div className="flex items-center space-x-3">
                <SparklesIcon className="h-5 w-5 text-violet-600 animate-pulse" />
                <p className="text-lg font-medium text-gray-900">{loadingMessage}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
            <div className="p-8">
              {error && (
                <div className="mb-6 p-4 bg-red-50 border border-red-100 rounded-xl">
                  <p className="text-sm text-red-600">{error}</p>
                </div>
              )}
              
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="url" className="block text-sm font-medium text-gray-700 mb-2">
                    Website URL
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <GlobeAltIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="url"
                      id="url"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      required
                      className="block w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-colors"
                      placeholder="https://your-website.com"
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Enter the URL of the website you want to generate content for
                  </p>
                </div>

                <button
                  type="submit"
                  className="w-full px-6 py-3 rounded-xl bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:from-violet-700 hover:to-indigo-700 transition-all duration-200 shadow-sm flex items-center justify-center space-x-2"
                  disabled={loading}
                >
                  <SparklesIcon className="h-5 w-5" />
                  <span>Generate Blog Content</span>
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default AddBlog;