import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { ClockIcon, CalendarIcon, ArrowLeftIcon } from '@heroicons/react/outline';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const UserBlogHome = () => {
  const { blogId } = useParams();
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [websiteName, setWebsiteName] = useState('');
  const [themeColor, setThemeColor] = useState('#000000');
  const [textColor, setTextColor] = useState('#FFFFFF');
  const [originalUrl, setOriginalUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (!blogId) {
          throw new Error("Blog ID is undefined");
        }

        const [userId, actualBlogId] = blogId.split('-');
        const postsCollectionPath = `users/${userId}/blogs/${actualBlogId}/posts`;
        const blogDocPath = `users/${userId}/blogs/${actualBlogId}`;

        const blogDoc = await getDoc(doc(db, blogDocPath));
        if (blogDoc.exists()) {
          const blogData = blogDoc.data();
          setWebsiteName(blogData.websiteName || 'Blog');
          const color = blogData.colors && blogData.colors.length > 0 ? blogData.colors[0] : '#000000';
          setThemeColor(color);
          setTextColor(getTextColor(color));
          setOriginalUrl(blogData.websiteUrl || '');
        } else {
          setWebsiteName('Blog');
        }

        const postsCollection = collection(db, postsCollectionPath);
        const postsSnapshot = await getDocs(postsCollection);

        if (postsSnapshot.empty) {
          setError("No posts found for this blog.");
          return;
        }

        const postsData = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPosts(postsData);
      } catch (err) {
        console.error('Error fetching posts:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (blogId === 'test-long-random-string') {
      const dummyPosts = [
        { id: '1', title: 'Lorem Ipsum Dolor Sit Amet', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        { id: '2', title: 'Consectetur Adipiscing Elit', content: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },
        { id: '3', title: 'Sed Do Eiusmod Tempor', content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' },
      ];
      setPosts(dummyPosts);
      setWebsiteName('Test Blog');
      setThemeColor('#000000');
      setTextColor('#FFFFFF');
      setLoading(false);
    } else {
      fetchPosts();
    }
  }, [blogId]);

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  const getTextColor = (bgColor) => {
    if (!bgColor) return '#000000';
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 186 ? '#000000' : '#FFFFFF';
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-violet-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="max-w-md p-6 bg-white rounded-2xl shadow-sm border border-red-100">
          <p className="text-red-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section with Blog Title */}
      <div 
        className="relative overflow-hidden bg-gradient-to-r from-violet-600 to-indigo-600"
        style={{ backgroundColor: themeColor }}
      >
        <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {originalUrl && (
            <a
              href={originalUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-4 py-2 rounded-full bg-white/10 hover:bg-white/20 text-white transition-colors mb-8"
            >
              <ArrowLeftIcon className="h-4 w-4 mr-2" />
              Back to Main Site
            </a>
          )}
          <h1 className="text-4xl md:text-5xl font-bold text-white text-center">
            {websiteName}
          </h1>
        </div>
      </div>

      {/* Blog Posts Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {posts.map(post => (
            <article 
              key={post.id}
              className="flex flex-col bg-white rounded-2xl shadow-sm hover:shadow-xl transition-all duration-300 border border-gray-100 overflow-hidden"
            >
              <a href={`/${blogId}/post/${post.id}`} className="block p-6">
                <h2 className="text-xl font-semibold text-gray-900 hover:text-violet-600 transition-colors line-clamp-2">
                  {stripLeadingHash(post.title)}
                </h2>
                <div className="mt-4 prose prose-sm text-gray-600 line-clamp-3">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {post.content ? post.content.substring(0, 150) + '...' : ''}
                  </ReactMarkdown>
                </div>
                <div className="mt-6 flex items-center text-sm text-gray-500">
                  <CalendarIcon className="h-4 w-4 mr-2" />
                  {post.createdAt && post.createdAt.toDate().toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </div>
              </a>
            </article>
          ))}
        </div>

        {posts.length === 0 && (
          <div className="text-center py-12">
            <h2 className="text-xl font-semibold text-gray-900">No posts yet</h2>
            <p className="mt-2 text-gray-600">Check back soon for new content!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserBlogHome;
